<template>
  <!-- 我的 -->
  <div class="container">
    <div class="top">
      <img class="back-img" src="@/assets/img/my_bg.png" alt="" />
      <img class="pic" :src="wxInfo.headImgUrl" alt="" />
      <span class="name">{{ userInfo.realName?userInfo.realName:wxInfo.nickname }}</span>
      <span class="phone">{{ userInfo.phone }}</span>
    </div>

    <div class="menu-out">
      <div class="menu">
        <div class="item" @click="goPage('myProperty')">
          <img src="@/assets/icon/house.png" alt="" />
          <div class="right">
            <span>我的房子</span>
            <img src="@/assets/icon/right.png" alt="" />
          </div>
        </div>

        <div class="item" @click="goPage('myCar')">
          <img src="@/assets/icon/car.png" alt="" />
          <div class="right">
            <span>我的车</span>
            <img src="@/assets/icon/right.png" alt="" />
          </div>
        </div>

        <!-- <div class="item">
          <img src="@/assets/icon/family.png" alt="" />
          <div class="right">
            <span>家庭成员</span>
            <img src="@/assets/icon/right.png" alt="" />
          </div>
        </div> -->

        <div class="item" @click="clear">
          <img src="@/assets/icon/clear.png" alt="" />
          <div class="right">
            <span>清理缓存</span>
            <img src="@/assets/icon/right.png" alt="" />
          </div>
        </div>

        <!-- <div class="item">
          <img src="@/assets/icon/logout.png" alt="" />
          <div class="right">
            <span>退出系统</span>
            <img src="@/assets/icon/right.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>

    <van-tabbar v-model="active" @change="onChange" z-index="10" active-color="#2f54eb" placeholder>
      <van-tabbar-item name="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.home_act : icon.home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/community">
        <span>服务</span>
        <template #icon="props">
          <img :src="props.active ? icon.serve_act : icon.serve" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  name="/my">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.my_act : icon.my" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { removeToken, removeUsernfo } from '@/utils/token.js'
export default {
  data() {
    return {
      active: "/my",
      icon: {
        home_act: require('@/assets/tabbar/home_act.png'),
        home: require('@/assets/tabbar/home.png'),
        serve_act: require('@/assets/tabbar/serve_act.png'),
        serve: require('@/assets/tabbar/serve.png'),
        my_act: require('@/assets/tabbar/my_act.png'),
        my: require('@/assets/tabbar/my.png'),
      },
    }
  },
  created() {
    // this.getList()
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
    wxInfo() {
      return this.$store.getters.wxInfo
    }
  },
  mounted() {},
  methods: {
    onChange (index) {
      this.$router.replace(index)
    },
    goPage(val) {
      window.location.href = val
      // this.$router.push({
      //   path: val
      // })
    },
    clear() {
      this.$store.commit('SET_IS_LOADING', true)
      removeToken()
      removeUsernfo()
      
      setTimeout(() => {
        this.$store.commit('SET_IS_LOADING', false)
        location.reload()
      }, 200)
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  .top {
    width: 100%;
    height: 280px;
    position: relative;
    color: #fff;
    .back-img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 280px;
    }
    .pic,
    .name,
    .phone {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }
    .pic {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #fff;
      top: 44px;
    }
    .name {
      top: 136px;
      font-size: 20px;
    }
    .phone {
      top: 168px;
      font-size: 15px;
    }
  }

  .menu-out {
    position: relative;
  }
  .menu {
    width: 350px;
    box-sizing: border-box;
    // margin: 0 12px;
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    .item {
      width: 100%;
      height: 60px;
      padding: 0 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 14px;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
